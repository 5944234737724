<script setup>
	import { twitter, facebook, pageLink } from '@/composables/helpers/socialSharing.js';
	const props = defineProps({
		article: {
			type: Object,
			required: true,
			default: () => ({}),
		},
		attributes: {
			type: Object,
			default: () => ({}),
		},
	});

	const { article } = toRefs(props);

	const validateArticle = computed(() => {
		if (!article.value?.content) return null;
		if (!article.value?.content?.name) return null;
		return article;
	});
	const formattedArticle = computed(() => {
		if (!validateArticle) return null;

		let rootPost = article.value?.content?.data || null;

		return rootPost;
	});

	const twitterLink = computed(() => {
		if (process.client) {
			return twitter(window.location.href);
		}
	});

	const facebookLink = computed(() => {
		if (process.client) {
			return facebook(window.location.href);
		}
	});

	const pageHref = computed(() => {
		if (process.client) {
			return window.location.href;
		}
	});

	const showCopiedAlert = ref(false);
	const handleShareButtonClick = () => {
		pageLink(pageHref.value);
		showCopiedAlert.value = true;

		setTimeout(() => {
			showCopiedAlert.value = false;
		}, 2000);
	};
</script>

<template>
	<section class="block block-encyclopedia-detail-header bg-white" tabindex="-1">
		<div class="container">
			<div class="eyebrow">
				<ul class="pill-list">
					<li v-if="formattedArticle?.category" v-html="formattedArticle.category" class="pill utility-button"></li>
				</ul>

				<div class="share">
					<p class="h5">Share</p>
					<!-- TODO: Fix this on SSR -->
					<!-- Quick hack, but not great. We should change window.location to useRoute() -->
					<ClientOnly>
						<ul class="socials">
							<li class="item">
								<MessLink v-if="facebookLink" :href="facebookLink" target="_blank">
									<svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M9.06343 10.0619L9.54787 6.98672H6.56376V4.98785C6.56376 4.14698 6.98037 3.32533 8.31256 3.32533H9.68835V0.706614C8.88716 0.578966 8.07759 0.509908 7.26619 0.5C4.81011 0.5 3.20664 1.97513 3.20664 4.64189V6.98672H0.484131V10.0619H3.20664V17.5H6.56376V10.0619H9.06343Z"
											fill="currentColor"
										/>
									</svg>
								</MessLink>
							</li>
							<li class="item">
								<MessLink v-if="twitterLink" :href="twitterLink" target="_blank">
									<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M-2.30853e-05 0.140868H0.152096C1.88867 0.140868 3.62525 0.141307 5.36183 0.138672C5.453 0.138672 5.50743 0.166343 5.5614 0.233985C7.20121 2.30013 8.84335 4.36451 10.485 6.42934C10.4967 6.44383 10.5092 6.45701 10.5302 6.48073C10.7414 6.2677 10.9488 6.05819 11.1563 5.84868C12.2509 4.74137 13.3455 3.63363 14.4401 2.52633C15.2044 1.75329 15.9683 0.980678 16.734 0.208949C16.7633 0.17952 16.808 0.144382 16.8457 0.144382C17.3332 0.13999 17.8212 0.141307 18.3394 0.141307C15.9581 2.55664 13.5939 4.95396 11.2233 7.35875C13.8112 10.613 16.3981 13.866 18.9995 17.1378C18.9381 17.1378 18.9014 17.1378 18.8641 17.1378C17.081 17.1378 15.2979 17.1378 13.5149 17.1395C13.4409 17.1395 13.3976 17.1189 13.353 17.0627C11.6396 14.8889 9.92447 12.7165 8.20976 10.5436C8.19394 10.5239 8.17673 10.505 8.15207 10.4764C8.05252 10.5761 7.95762 10.671 7.86318 10.7663C6.05683 12.5979 4.25047 14.4295 2.44412 16.2611C2.17756 16.5317 1.91193 16.8031 1.64351 17.0719C1.61467 17.1009 1.56908 17.1338 1.5314 17.1343C1.03178 17.1382 0.532161 17.1369 -0.000488281 17.1369C2.49529 14.6118 4.97292 12.1051 7.458 9.59049C4.97664 6.44559 2.49668 3.30333 -2.30853e-05 0.140868ZM2.3869 1.25959C2.43388 1.31932 2.46272 1.35754 2.4925 1.39487C4.39608 3.78165 6.29966 6.168 8.20278 8.55478C10.1454 10.9912 12.0881 13.4276 14.0294 15.8649C14.0759 15.9233 14.1247 15.9466 14.2033 15.9462C14.9746 15.9431 15.7459 15.9444 16.5172 15.9444C16.5544 15.9444 16.5917 15.9409 16.6456 15.9378C16.6093 15.8895 16.5837 15.8539 16.5568 15.8197C14.4159 13.1215 12.2746 10.4233 10.1343 7.72507C8.44608 5.59744 6.75834 3.4698 5.07201 1.34041C5.02502 1.28155 4.9785 1.25739 4.90035 1.25783C4.1058 1.26047 3.31124 1.25959 2.51669 1.25959C2.48273 1.25959 2.44877 1.25959 2.3869 1.25959Z"
											fill="currentColor"
										/>
									</svg>
								</MessLink>
							</li>
							<li class="item">
								<div v-show="showCopiedAlert" class="copied-alert-message">
									<p class="utility">Link Copied!</p>
								</div>
								<button
									@click.prevent="handleShareButtonClick"
									:class="{'show-copied-alert': showCopiedAlert}"
									class="mess-link share-button"
								>
									<svg
										fill="currentColor"
										version="1.1"
										id="Capa_1"
										xmlns="http://www.w3.org/2000/svg"
										width="20px"
										height="20px"
										viewBox="0 0 442.246 442.246"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													d="M409.657,32.474c-43.146-43.146-113.832-43.146-156.978,0l-84.763,84.762c29.07-8.262,60.589-6.12,88.129,6.732 l44.063-44.064c17.136-17.136,44.982-17.136,62.118,0c17.136,17.136,17.136,44.982,0,62.118l-55.386,55.386l-36.414,36.414 c-17.136,17.136-44.982,17.136-62.119,0l-47.43,47.43c11.016,11.017,23.868,19.278,37.332,24.48 c36.415,14.382,78.643,8.874,110.467-16.219c3.06-2.447,6.426-5.201,9.18-8.262l57.222-57.222l34.578-34.578 C453.109,146.306,453.109,75.926,409.657,32.474z"
												/>
												<path
													d="M184.135,320.114l-42.228,42.228c-17.136,17.137-44.982,17.137-62.118,0c-17.136-17.136-17.136-44.981,0-62.118 l91.8-91.799c17.136-17.136,44.982-17.136,62.119,0l47.43-47.43c-11.016-11.016-23.868-19.278-37.332-24.48 c-38.25-15.3-83.232-8.262-115.362,20.502c-1.53,1.224-3.06,2.754-4.284,3.978l-91.8,91.799 c-43.146,43.146-43.146,113.832,0,156.979c43.146,43.146,113.832,43.146,156.978,0l82.927-83.845 C230.035,335.719,220.243,334.496,184.135,320.114z"
												/>
											</g>
										</g>
									</svg>
								</button>
							</li>
						</ul>
					</ClientOnly>
				</div>
			</div>
			<header>
				<h1 v-if="formattedArticle?.title" v-html="formattedArticle.title" class="building-title h2"></h1>
				<div v-if="formattedArticle?.description" v-html="formattedArticle?.description" class="rich-text"></div>
			</header>

			<FragmentsCarouselsSimpleCarouselWithControls
				v-if="formattedArticle?.imageList?.length"
				:carouselCards="formattedArticle?.imageList"
			/>
		</div>
	</section>
</template>

<style lang="scss">
	.block-encyclopedia-detail-header {
		--vh: calc((100vh - 100px) / 100);

		.eyebrow {
			margin-bottom: 35px;

			@media (min-width: $mobile) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			.pill-list {
				display: flex;
				flex-wrap: wrap;
				margin: -6px -12px;

				.pill {
					margin: 6px 12px;
					background-color: var(--gray-100);
				}
			}

			.share {
				display: flex;
				align-items: center;
				margin-top: 24px;

				@media (min-width: $mobile) {
					margin-top: 0;
				}

				.h5 {
					margin-right: 16px;
					margin-bottom: 0;
				}

				.socials {
					display: flex;
					margin: -6px -12px;

					.item {
						z-index: 0;
						position: relative;
						margin: 6px 12px;

						.mess-link {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 41px;
							height: 41px;
							border-radius: 100%;
							background-color: var(--white);
							border: 1px solid var(--gray-500);

							svg {
								transition: transform 0.25s ease-in-out;
							}

							&:hover,
							&:focus,
							&:focus-visible,
							&:focus-within {
								svg {
									transform: scale(0.8);
									opacity: 0.8;
								}

							}
						}

						.share-button {
							&.show-copied-alert {
								color: var(--red);
							}
						}

						.copied-alert-message {
							z-index: 1;
							position: absolute;
							top: -30px;
							left: 0;
							padding: 0.5em;
							background-color: var(--white);
							border-radius: 5px;
							box-shadow: 0px 1px 5px 2px rgba(0,0,0, 0.25);
						}
					}
				}
			}
		}

		header {
			margin-bottom: 45px;

			.building-title {
				width: 90%;

				span {
					font-size: inherit;
					font-weight: inherit;
					line-height: inherit;
					letter-spacing: inherit;
				}
			}

			.rich-text,
			.rich-text p {
				font-size: calc(18 / var(--base-fs) * 1rem);

				@media (min-width: $tablet) {
					font-size: calc(24 / var(--base-fs) * 1rem);
				}
			}
		}

		.container {
			.inner-container {
				max-width: 82.35%;
				margin: 0 auto;

				@media (max-width: $mobile) {
					max-width: 100%;
				}
			}
		}

		.simple-carousel {
			ul {
				max-height: calc(80 * var(--vh));

				.caption-card {

					&:nth-child(1) {

						.mess-image img {
							object-fit: cover;
						}
					}
				}
			}
		}
	}
</style>
